// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bC_k5";
export var caseStudyBackground__lineColor = "bC_kX";
export var caseStudyHead = "bC_k7";
export var caseStudyHead__imageWrapper = "bC_kV";
export var caseStudyProjectsSection = "bC_k6";
export var caseStudyQuote__bgRing = "bC_k2";
export var caseStudyVideo__ring = "bC_k8";
export var caseStudy__bgDark = "bC_kT";
export var caseStudy__bgDarkReverse = "bC_l7";
export var caseStudy__bgLight = "bC_kS";